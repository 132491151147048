import CONFIG from 'consts/config';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import cookie from 'utils/cookie';
import { getBlogListPath } from 'utils/getBlogListPath';

export const PAGE_URLS = {
  baseUrl: CONFIG.SITE_URL,
  landingPage: () => {
    const lpURL = cookie.get(COOKIE_KEYS.lpUrl);
    return lpURL && lpURL.includes('.co/') ? `/free-trial/1i0g/` : '/';
  },
  careers: '/careers/',
  community: '/community/',
  contactUs: '/contact-us/',
  doNotSell: '/donotsellorshare/',
  homepage: '/',
  marketingSafeLandingPage: '/free-trial/1i0g/',
  ourStory: '/our-story/',
  reviews: '/skincare/reviews/',
  whyCurology: '/why-curology/',
  howItWorks: '/how-it-works/',

  products: {
    customFormulaAcne: '/products/custom-formula-acne/',
    bodyCleanse: '/products/body-cleanse/',
    customFormulaAntiAging: '/products/custom-formula-anti-aging/',
    futureProofAntiAging: '/products/future-proof-anti-aging/',
    hairFormula: '/products/hair-formula/',
  },

  // BELOW ending slash is needed for activeLink style to work
  disclaimer: '/disclaimer/',
  guardianTelehealthConsent: '/guardian-telehealth-consent/',
  privacy: '/privacy/',
  statePrivacyNoticeAddenda: '/stateprivacynoticeaddenda',
  washingtonHealthPrivacy: '/washingtonhealthprivacy',
  nevadaHealthPrivacy: '/nevadahealthprivacy',
  telehealthConsent: '/telehealth-consent/',
  terms: '/terms/',

  blog: {
    home: getBlogListPath(),
    hydroquinone: '/blog/behind-the-ingredient-hydroquinone/',
    acneFaceMapping:
      '/blog/pimple-face-mapping-what-pimples-on-specific-areas-of-your-face-means/',
    quiz: '/blog/skincare-personality-quiz/',
    category: {
      curology: getBlogListPath('curology'),
      skinConcerns: getBlogListPath('skin-concerns'),
      skinTreatment: getBlogListPath('skincare-treatment'),
      skinProducts: getBlogListPath('skincare-products'),
      ingredients: getBlogListPath('skincare-ingredients'),
      mindAndBody: getBlogListPath('health-and-wellness'),
      video: getBlogListPath('video'),
    },
  },
};
